import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { StaticImage } from "gatsby-plugin-image";

const WhatWeDidSection = ({ whatWeDid }) => {
	return (
		<section className="py-5 py-lg-7 bg-light-grey">
			<Container>
				<Row className="mb-5">
					<Col lg={5}>
						<h2 className="display-4  text-primary">{whatWeDid?.title}</h2>
					</Col>
					<Col lg={7}>
						<p>{whatWeDid?.whatWeDidCaseStudyFields.description}</p>
					</Col>
				</Row>
				<Row className="g-5">
					{whatWeDid?.whatWeDidCaseStudyFields.servicesProvided.webDesign ===
						true && (
						<Col lg={6}>
							<div
								style={{
									width: "40px",
									height: "40px",
									borderRadius: "50%",
									boxShadow: "0px 3px 6px #00000029",
								}}
								className="d-flex align-items-center mb-4 justify-content-center bg-white"
							>
								<StaticImage
									src="../images/webdesign-blue.svg"
									placeholder="blurred"
									quality={100}
									style={{ maxWidth: "20px" }}
								/>
							</div>
							<h3 className="fs-2">
								{whatWeDid?.whatWeDidCaseStudyFields.webDesign.title}
							</h3>
							<p>{whatWeDid?.whatWeDidCaseStudyFields.webDesign.description}</p>
						</Col>
					)}
					{whatWeDid?.whatWeDidCaseStudyFields.servicesProvided.seo ===
						true && (
						<Col lg={6}>
							<div
								style={{
									width: "40px",
									height: "40px",
									borderRadius: "50%",
									boxShadow: "0px 3px 6px #00000029",
								}}
								className="d-flex align-items-center mb-4 justify-content-center bg-white"
							>
								<StaticImage
									src="../images/SEO-blue.svg"
									placeholder="blurred"
									quality={100}
									style={{ maxWidth: "20px" }}
								/>
							</div>
							<h3 className="fs-2">
								{whatWeDid?.whatWeDidCaseStudyFields.seo.title}
							</h3>
							<p>{whatWeDid?.whatWeDidCaseStudyFields.seo.description}</p>
						</Col>
					)}
					{whatWeDid?.whatWeDidCaseStudyFields.servicesProvided.branding ===
						true && (
						<Col lg={6}>
							<div
								style={{
									width: "40px",
									height: "40px",
									borderRadius: "50%",
									boxShadow: "0px 3px 6px #00000029",
								}}
								className="d-flex align-items-center mb-4 justify-content-center bg-white"
							>
								<StaticImage
									src="../images/webdesign-blue.svg"
									placeholder="blurred"
									quality={100}
									style={{ maxWidth: "20px" }}
								/>
							</div>
							<h3 className="fs-2">
								{whatWeDid?.whatWeDidCaseStudyFields.branding.title}
							</h3>
							<p>{whatWeDid?.whatWeDidCaseStudyFields.branding.description}</p>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default WhatWeDidSection;
