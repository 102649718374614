import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CaseStudyReviewSection = ({ name, review, quoteColour, textColour }) => {
	return (
		<section className="py-5 py-lg-7 ">
			<Container>
				<Row className="g-5 align-items-center">
					<Col md={5}>
						<h2 className={`display-5 text-primary ${textColour} `}>
							{name}
							<br />
							<span className="text-dark-blue">Customer feedback</span>
						</h2>
					</Col>
					<Col className=" text-center  px-4 pe-lg-0 mt-lg-0" lg={7}>
						<div style className="position-relative d-inline-block pt-4 ">
							<div
								style={{ width: "20px", left: "-25px", color: quoteColour }}
								className="position-absolute display-2 top-0"
							>
								"
							</div>
							<div
								style={{ width: "20px", right: "-25px", color: quoteColour }}
								className="position-absolute display-2  top-0"
							>
								"
							</div>

							<p className="text-center">{review}</p>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CaseStudyReviewSection;
