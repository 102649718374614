import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage } from "gatsby-plugin-image";

const MobileImagesSection = ({
	image1,
	imageAlt1,
	image2,
	imageAlt2,
	image3,
	imageAlt3,
	title,
}) => {
	return (
		<section className="py-5 py-lg-7 bg-light-grey">
			<Container>
				<Row>
					<Col>
						<h2 className="display-5 text-primary mb-5">{title}</h2>
					</Col>
				</Row>
				<Row className="g-5">
					<Col md={4}>
						<GatsbyImage image={image1} alt={imageAlt1} className="w-100" />
					</Col>
					<Col md={4}>
						<GatsbyImage image={image2} alt={imageAlt2} className="w-100" />
					</Col>
					<Col md={4}>
						<GatsbyImage image={image3} alt={imageAlt3} className="w-100" />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default MobileImagesSection;
