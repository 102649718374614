import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const ResultsSectionCaseStudies = ({
	title,
	mainText,
	image,
	imageAlt,
	bgColour,
	result1Title,
	result1Text,
	result2Title,
	result2Text,
	result3Title,
	result3Text,
}) => {
	return (
		<section id="results" className=" py-5 py-lg-7 bg-black">
			<Container>
				<Row className="align-items-center g-5 py-5 pt-xl-0">
					<Col lg={{ span: 6, order: "last" }}>
						<div style={{ overflow: "hidden", borderRadius: "15px" }}>
							<GatsbyImage className="w-100" image={image} alt={imageAlt} />
						</div>
					</Col>
					<Col lg={6} className="  mt-lg-0">
						<h2 className="display-5 text-white mb-4">{title}</h2>
						<span className="text-white">{mainText}</span>
						<Button
							className=" w-100 w-md-auto mt-4 fs-5 primary-link py-2 px-4 "
							variant="white"
							as={Link}
							to="/contact-us"
						>
							Get in touch
						</Button>
					</Col>
				</Row>

				<Row
					style={{ background: bgColour, borderRadius: "15px" }}
					className="align-items-center text-white mt-5 py-5 py-lg-0 gy-5 gy-lg-0"
				>
					<Col xs={12} className="text-center p-4" lg={true}>
						<StaticImage
							src="../images/SEARCH ENGINE-OPTIMISATION-ICON1.svg"
							placeholder="blurred"
							quality={100}
							className="mw-100 "
						/>
					</Col>
					<Col lg={true}>
						<h2 className="ssp-bold text-center text-lg-start fs-3">
							Our results:
						</h2>
					</Col>
					<Col className="text-center" lg={true}>
						<p className="ssp-bold pb-1 mb-0 display-4">{result1Title}</p>
						<h3 className="fs-5">{result1Text}</h3>
					</Col>
					<Col className="text-center" lg={true}>
						<p className="ssp-bold  pb-1 mb-0 display-4">{result2Title}</p>
						<h3 className="fs-5">{result2Text}</h3>
					</Col>
					<Col className="text-center" lg={true}>
						<p className="ssp-bold  pb-1 mb-0 display-4">{result3Title}</p>
						<h3 className="fs-5">{result3Text}</h3>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ResultsSectionCaseStudies;
